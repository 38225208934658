<template>
  <Page full>
    <grid
      :isMain="true"
      title="Marketing templates"
      type="template"
      :permanentFilters="{
        template_type_id: '13'
      }"
    />
  </Page>
</template>

<script>
import Grid from '../ui/Grid/Grid.vue'
import PageMixin from '../mixins/Page'

export default {
  mixins: [PageMixin],
  name: '',
  data() {
    return {}
  },
  methods: {
    editTemplate(template) {
      this.$router.push(`template/${template.template_id}`).catch(() => {})
    }
  },
  components: {
    Grid
  },
  props: {},
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
